<div class="container-fluid vh-100 login-look">
  <div class="row header-background">
    <!-- Left Section -->
    <div class="col-md-8">
      <div class="content-keeper">
        <img src="../../../assets/images/pharmteco-logo-240x84-white.png" alt="White logo SK pharmteco" class="logo"/>
        <div class="co-info">
            <h1>Welcome to the Client Portal!</h1>
            <p>
                Your one-stop solution to access project details, track progress, and manage requests in real time.
            </p>
        </div>
      </div>
    </div>
    <!-- Right Section (Login Form) -->
    <div
      class="col-md-4 d-flex align-items-center justify-content-center"
    >
      <div class="shadow-lg login-box">
        <div class="text-center mb-4">
          <h4 class="fw-bold">Log In</h4>
        </div>
        @if(!isEmailEntered) {
        <form [formGroup]="emailForm" (ngSubmit)="onSubmitEmailId()">
          <div class="mb-4">
            <label for="email" class="form-label">Email ID 
              <span class="required"></span>
            </label>
            <input
              tabindex="1"
              id="email"
              formControlName="email"
              type="text"
              class="form-control"
              [ngClass]="{
                'error-border':
                  emailForm.get('email')?.touched &&
                  emailForm.get('email')?.invalid
              }"
              placeholder="Enter Email ID"
            />
            @if(emailForm.get('email')?.dirty &&
            emailForm.get('email')?.invalid) {
            <div class="text-danger">
              @if(emailForm.get('email')?.errors?.['required']) {
              <small>Email ID is required.<br /></small>
              } @if(emailForm.get('email')?.errors?.['pattern']) {
              <small>{{ Messages.EMAIL_VALIDATION_MSG }}<br /></small>
              }
            </div>
            }
          </div>

          <div class="d-flex mt-4">
            <button
              type="submit"
              class="btn btn-danger w-100 rounded-button"
              [disabled]="emailForm.invalid"
              [ngClass]="{
                'error-border':
                  emailForm.get('email')?.touched &&
                  emailForm.get('email')?.invalid
              }"
            >
              Proceed
            </button>
          </div>
        </form>
        } @else if(isEmailEntered) {
        <form [formGroup]="passwordForm" (ngSubmit)="onSubmitPassword()">
          <img
            src="../../../assets/icons/left-arrow.png"
            (click)="backToEmail()"
            title="Back"
            class="p-0 position-relative back-button back-btn"
            style="height: 20px"
          />
          &nbsp;
          <label for="email" class="form-label greyed">
            <h6>{{ emailForm.value.email }}</h6>
          </label>

          <div class="mb-2">
            <label for="password" class="form-label">Password <span class="required"></span></label>
            <div class="input-group password-input">
              <button
                type="button"
                class="btn-left"
                [ngClass]="{
                  'error-border-tbl':
                    passwordForm.get('password')?.touched &&
                    passwordForm.get('password')?.invalid
                }"
              >
                <img src="assets/icons/lock.png" />
              </button>
              <input
                tabindex="2"
                [type]="hidePassword ? 'password' : 'text'"
                id="password"
                formControlName="password"
                class="form-control btn-middle"
                [ngClass]="{
                  'error-border-tb':
                    passwordForm.get('password')?.touched &&
                    passwordForm.get('password')?.invalid
                }"
                placeholder="Enter your password"
              />
              <button
                type="button"
                class="btn-right"
                (click)="hidePassword = !hidePassword"
                [ngClass]="{
                  'error-border-trb':
                    passwordForm.get('password')?.touched &&
                    passwordForm.get('password')?.invalid
                }"
              >
                <i [class]="hidePassword ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                @if(passwordForm.get('password')?.dirty &&
                passwordForm.get('password')?.invalid) {
                <div class="text-danger">
                  @if(passwordForm.get('password')?.errors?.['required']) {
                  <small>Password is required.<br /></small>
                  }
                </div>
                }
              </div>
              <div class="col-12 text-end">
                <button
                  type="button"
                  class="font-weight-bold text-link p-0"
                  (click)="navigateToForgotPassword()"
                >
                  Forgot Password?
                </button>
              </div>
            </div>
            <div class="d-flex mt-4">
              <button
                tabindex="3"
                type="submit"
                class="btn btn-danger w-100 rounded-button"
                [disabled]="passwordForm.invalid"
              >
                Log In
              </button>
            </div>
          </div>
        </form>
        }
      </div>
    </div>
  </div>
  <div class="row py-3 fent-bg">
    <div class="col-md-8">
        <h3 class="py-3">Know More About SK pharmteco</h3>
      <div class="blog-holder row">
        <div class="col-md-4 blog">
          <img src="../../../assets/images/molecule-background.png" alt="Small Molecule"/>
          <h4>Small Molecule</h4>
          <p style="text-align:justify;">SK pharmteco is a custom manufacturer of active pharmaceutical ingredients (APIs, Drug Substances) and registered intermediates for customers in the pharmaceutical industry. Our integrated small molecule facilities located in the USA (California, Texas, Virginia), Ireland, and Korea specialize in process development, scale-up, and CGMP compliant production from kilograms to multi-ton quantities.</p>
          <a class="learn-more"  target="_blank" rel="noopener noreferrer" href="https://www.skpharmteco.com/small_molecules_services/">Learn More > </a>
        </div>
        <div class="col-md-4 blog">
          <img src="../../../assets/images/cell-and-gene.png" alt="Cell & Gene"/>
          <h4>Cell & Gene</h4>
          <p style="text-align:justify;">
              We have unified two leading CDMOs: Center for Breakthrough Medicines and Yposkesi. Together, we offer a comprehensive range of services across the advanced therapies process, from R&D to manufacturing and commercialization, with a global presence. Our unmatched expertise enables us to overcome challenges through transparent, productive partnerships that accelerate the realization of CGT.
          </p>
          <a class="learn-more" href="https://www.skpharmteco.com/cell-and-gene-therapy/"  target="_blank" rel="noopener noreferrer">Learn More > </a>
        </div>
        <div class="col-md-4 blog">
          <img src="../../../assets/images/analytical.png" alt="Analytical"/>
          <h4>Analytical</h4>
          <p  style="text-align:justify;">From Analytical Method Development and Implementation to Analytical Method Validation and stability, SK pharmteco Analytical is fully equipped with fully compliant CGMP instrumentation geared toward product analysis and product release. We support spectroscopy, chromatography, particle size distribution, calorimetry, osmolality, mass spectrometry and ICH Stability project requirements.</p>
          <a class="learn-more" href="https://www.skpharmteco.com/analytical-services/"  target="_blank" rel="noopener noreferrer">Learn More > </a>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="did-you-know">
        <div class="header-bg">
          <span class="q-mark-holder">
            <img src="../../../assets/images/q-mark.png" />
          </span>
          <h2>
            <span>Did you know</span> 
           </h2>
        </div>
        <ngb-carousel>
          <ng-template ngbSlide>
            <div class="slide-holder">
              <img src="../../../assets/images/image (4).png" class="img" alt="Manufacturing Unit"/>
              <div class="content-holder">
                We have <b>six manufacturing</b> sites across the U.S., Europe, and Korea, enabling us to deliver top-quality pharmaceutical solutions worldwide.
              </div>
            </div>
            <!-- <ul class="imppts">
              <li>
                We have <b>six manufacturing</b> sites across the U.S., Europe, and Korea, enabling us to deliver top-quality pharmaceutical solutions worldwide.
              </li>
              <li>
                Our <b>FDA, EMA, PMDA, and MFDS</b>-approved facilities meet the highest global standards for <b>safety, quality, and compliance.</b>
              </li>
              <li>
                We specialize in <b>gene therapies,</b> manufacturing viral vectors and <b>plasmid DNA</b> for next-generation treatments.
              </li> 
              <li>We're committed to reducing our environmental footprint through <b>sustainable practices, energy efficiency, and eco-friendly technologies.</b></li>
              <li>Our global <b>small molecule</b> capacity is over <b>815 m³</b> (~215,450 gallons), and we also have dedicated facilities for <b>large molecule</b> production</li>
            </ul> -->
          </ng-template>
          <ng-template ngbSlide>
            <div class="slide-holder">
              <img src="../../../assets/images/image (3).png" class="img" alt="Manufacturing Unit"/>
              <div class="content-holder">
                Our global <b>small molecule</b> capacity is over <b>815 m³</b> (~215,450 gallons), and we also have dedicated facilities for <b>large molecule</b> production
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="slide-holder">
              <img src="../../../assets/images/manufacture-unit.PNG" class="img" alt="Manufacturing Unit"/>
              <div class="content-holder">
                Our <b>FDA, EMA, PMDA, and MFDS</b>-approved facilities meet the highest global standards for <b>safety, quality, and compliance.</b>
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="slide-holder">
              <img src="../../../assets/images/manufacture-unit.PNG" class="img" alt="Manufacturing Unit"/>
              <div class="content-holder">
                We specialize in <b>gene therapies,</b> manufacturing viral vectors and <b>plasmid DNA</b> for next-generation treatments.
              </div>
            </div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="slide-holder">
              <img src="../../../assets/images/manufacture-unit.PNG" class="img" alt="Manufacturing Unit"/>
              <div class="content-holder">
                We're committed to reducing our environmental footprint through <b>sustainable practices, energy efficiency, and eco-friendly technologies.</b>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
  <app-toaster></app-toaster>
  <app-loader></app-loader>
</div>
