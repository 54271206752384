import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { NavigationURLs } from '../../utils/application-constants';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SsoInitializationService {

  constructor(
    public authService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private localStorageService: LocalStorageService
  ) { }

  initializeAuth(): void {
    this.msalBroadcastService.msalSubject$.subscribe((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_START) {
        localStorage.setItem('initiatedSsoLogin', 'true');
      } else if (message.eventType === EventType.LOGIN_SUCCESS) {
        const authResult = message.payload as AuthenticationResult;
        this.localStorageService.clear();
        const isSsoLogin = true;
        const isUserLoggedIn = true;
        this.localStorageService.setCurrentUser({ token: authResult?.accessToken, username: authResult?.account?.username, name: authResult?.account?.name }, isUserLoggedIn, isSsoLogin);
      } else if (message.eventType === EventType.LOGIN_FAILURE) {
        console.error('Login failed in AuthService', message);
        localStorage.removeItem('initiatedSsoLogin');
      }
    });

    this.authService.handleRedirectObservable()
      .subscribe({
        next: (result) => {
          if (result) {
            this.router.navigate([NavigationURLs.HOME_CLIENTS]);
          }
        },
        error: (error) => console.error('Error handling redirect observable in AuthService', error)
      });
  }

  login() {
    this.authService.loginRedirect({
      scopes: environment.apiConfig.scopes
    });
  }

  logout() {
    this.localStorageService.clear(); // Remove token from localStorage
    this.authService.logoutRedirect();
  }
}
