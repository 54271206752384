import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import {
  OKTA_AUTH,
  OktaAuthModule,
  OktaAuthStateService,
} from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { UserLoginRequest } from '../../interfaces/user-login-request';
import { GlobalService } from '../../services/global/global.service';
import { LoaderService } from '../../services/loader/loader.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { SsoInitializationService } from '../../services/sso-initialization/sso-initialization.service';
import { UserService } from '../../services/user/user.service';
import {
  Messages,
  NavigationURLs,
  SKPTConstants,
} from '../../utils/application-constants';
import { LoaderComponent } from '../shared/loader/loader.component';
import { ToasterComponent } from '../shared/toaster/toaster.component';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OktaAuthModule,
    ToasterComponent,
    LoaderComponent,
    NgbCarouselModule
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'], // Updated to use CSS
})
export class LoginComponent implements OnInit {
  @ViewChild(ToasterComponent) toaster!: ToasterComponent;

  emailForm: any;
  passwordForm: any;
  hidePassword: boolean = true;

  userLoginRequest: UserLoginRequest = {
    email: '',
    password: '',
  };

  errorMessage: string = '';
  isEmailEntered: boolean = false;
  Constants = SKPTConstants;
  Messages = Messages;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private ssoInitializationService: SsoInitializationService,
    private loaderService: LoaderService,
    public globalService: GlobalService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public authService: OktaAuthStateService
  ) { }

  async ngOnInit() {
    this.emailForm = this.fb.group({
      email: [
        // 'platformadmin@mailinator.com',
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(SKPTConstants.PATTERN_REQUIRED_CHARS_IN_USERNAME),
        ],
      ],
    });

    this.passwordForm = this.fb.group({
      password: [
        // '123Pa$$word!',
        '',
        [
          Validators.required
        ],
      ],
    });

    if (localStorage.getItem('initiatedSsoLogin')) {
      this.loaderService.showLoader();
      localStorage.removeItem('initiatedSsoLogin');

      try {
        await this.oktaAuth.handleLoginRedirect();
        await this.checkAuthentication();
      } catch (error) {
        console.error('Error handling login redirect:', error);
        this.loaderService.hideLoader();
      }
    }
  }

  private async checkAuthentication() {
    try {
      const isAuthenticated = await this.oktaAuth.isAuthenticated();

      if (isAuthenticated) {
        await this.retrieveTokens();
      } else {
        this.handleUnauthenticatedState();
      }
    } catch (error) {
      console.error('Error checking authentication status:', error);
      this.loaderService.hideLoader();
    }
  }

  private async retrieveTokens() {
    try {
      const tokens: Tokens = this.oktaAuth.tokenManager.getTokensSync();
      // const accessToken = tokens.accessToken?.accessToken; // Retrieve the ID token
      const accessToken = tokens.idToken?.idToken; // Retrieve the ID token

      if (accessToken) {
        const userClaims = await this.oktaAuth.getUser();
        this.setUserAndNavigate(userClaims, accessToken);
      } else {
        throw new Error('Error in fetching Access token.');
      }
    } catch (error) {
      console.error('Error retrieving tokens:', error);
      this.loaderService.hideLoader();
    }
  }

  private setUserAndNavigate(userClaims: any, accessToken: string) {
    const isSsoLogin = true;
    const isUserLoggedIn = true;

    this.localStorageService.clear();
    this.localStorageService.setCurrentUser(
      {
        token: accessToken,
        username: userClaims?.name,
        name: userClaims?.preferred_username,
        email: userClaims?.email,
      },
      isUserLoggedIn,
      isSsoLogin
    );
    this.router.navigate([NavigationURLs.HOME]);
  }

  private handleUnauthenticatedState() {
    setTimeout(() => {
      this.emailForm.reset();
      this.passwordForm.reset();
      this.loaderService.hideLoader();
      this.localStorageService.clear();
      sessionStorage.clear();
    }, 2000);
  }

  // Custom validator function
  disallowedCharactersValidator(disallowedChars: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      } // No value, no error

      const foundChars = disallowedChars.filter((char) =>
        control.value.includes(char)
      );
      if (foundChars.length > 0) {
        return { disallowedCharacters: foundChars };
      }
      return null;
    };
  }

  onSubmit(): void {
    this.loaderService.showLoader();
    this.userService.authenticateUser(this.userLoginRequest).subscribe({
      next: (result: any) => {
        this.localStorageService.clear();
        if (result.success) {
          const isSsoLogin = false;
          const isUserLoggedIn = true;
          this.localStorageService.setCurrentUser(
            result?.data,
            isUserLoggedIn,
            isSsoLogin
          );

          // initialize signalR connection on Home Page after log in
          this.router.navigate([NavigationURLs.HOME]);
          // this.loaderService.hideLoader();
        } else {
          this.toaster.showMessage(`${result?.message}`, 'error', 6000);
          console.error(`${result?.message}`);
          this.loaderService.hideLoader();
        }
      },
      error: (error: any) => {
        this.loaderService.hideLoader();
        if (error?.status === 401) {
          if (error?.error?.message === Messages.WRONG_PASSWORD) {
            this.errorMessage = Messages.INVALID_CREDENTIALS;
            this.toaster.showMessage(this.errorMessage, 'error');
          }
          if (error?.error?.message === Messages.WRONG_EMAIL) {
            this.errorMessage = Messages.WRONG_EMAIL;
            this.toaster.showMessage(this.errorMessage, 'error');
          }
        }
        if (error?.status === 400) {
          if (error?.error?.errors?.Password[0]) {
            this.errorMessage = Messages.INVALID_CREDENTIALS;
            this.toaster.showMessage(this.errorMessage, 'error', 6000);
          } else if (error?.error?.message) {
            this.toaster.showMessage(`${error?.error?.message}`, 'error', 6000);
            console.error(`${error?.error?.message}`);
          } else {
            this.toaster.showMessage(`${error.message}`, 'error', 6000);
            console.error(`${error.message}`);
          }
        } else {
          this.toaster.showMessage(
            'Error logging in, please contact the admin.',
            'error'
          );
          console.error('Error logging in, please contact the admin.');
          return;
        }
      },
    });
  }

  navigateToForgotPassword(): void {
    this.router.navigate([NavigationURLs.FORGOT_PASSWORD]); // Navigate to the Reset Password Page
  }

  redirectToSsoLogin() {
    this.loaderService.showLoader();
    this.ssoInitializationService.login();
  }

  async onSubmitEmailId(): Promise<void> {
    const emailId: string = this.emailForm.value.email;
    if (emailId && this.checkIfEmailHasListedDomain(emailId)) {
      this.loaderService.showLoader();

      try {
        localStorage.setItem('initiatedSsoLogin', 'true');
        // await this.oktaAuth.signInWithRedirect({ prompt: 'login' });
        await this.oktaAuth.signInWithRedirect({ loginHint: emailId });
      } catch (err: any) {
        console.error(err);
      }
    } else {
      this.isEmailEntered = true;
      this.loaderService.showLoader();
      this.userLoginRequest.email = emailId;
      setTimeout(() => {
        this.loaderService.hideLoader();
      }, 100);
    }
  }

  onSubmitPassword(): void {
    const password: string = this.passwordForm.value.password;
    if (password) {
      this.userLoginRequest.password = password;
    }
    this.onSubmit();
  }

  checkIfEmailHasListedDomain(emailId: string): boolean {
    if (!emailId || !environment?.ssoEmailDomains) {
      return false;
    }
  
    const domain = emailId.split('@')[1]?.toLowerCase(); // Extract domain part
    if (!domain) {
      return false;
    }
  
    return environment.ssoEmailDomains.some((listedDomain) =>
      domain === listedDomain.toLowerCase()
    );
  }

  backToEmail(): void {
    this.loaderService.showLoader();
    this.passwordForm.reset();
    this.isEmailEntered = false;
    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 50);
  }
}
